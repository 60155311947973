var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information && _vm.license
    ? _c(
        "section",
        [
          _vm.information.node.global_server_id == 0
            ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                _vm._v(_vm._s(_vm.$t("ORGANISATION.NOT_ACTIVATED"))),
              ])
            : _vm._e(),
          _c("dl", { staticClass: "mt-0 pt-0 clearfix" }, [
            _c("dt", [_vm._v(_vm._s(_vm.$t("DESCRIPTION")))]),
            _c("dd", [_vm._v(_vm._s(_vm.information.node.description))]),
            _vm.information.node.global_server_id == 0
              ? _c("dt", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("ACTIVATION_CODE")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm.information.node.global_server_id == 0
              ? _c("dd", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.information.node.activation_token) +
                      " "
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "fal fa-copy clickable",
                    attrs: { title: _vm.$t("COPY_CLIPBOARD") },
                    on: { click: _vm.copyActicationToken },
                  }),
                ])
              : _vm._e(),
            _vm.information.node.global_server_id != 0
              ? _c("dt", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("HOSTNAME")) + "\n      "
                  ),
                ])
              : _vm._e(),
            _vm.information.node.global_server_id != 0
              ? _c("dd", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.information.node.hostname) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _c("dt", [_vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE")))]),
            _c(
              "dd",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Administration.Organisation.Information",
                        params: {
                          organisation_id: _vm.information.organisation.id,
                        },
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.information.organisation.name))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              _vm._s(_vm.$t("INFORMATION")) + "\n        "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1071631124
                  ),
                },
                [
                  _c("Edit", {
                    attrs: { license: _vm.license, node: _vm.information },
                    on: { updatedInformation: _vm.updatedInformation },
                  }),
                ],
                1
              ),
              _vm.user.information.is_admin > 0
                ? _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n         " +
                                    _vm._s(_vm.$t("PRICES")) +
                                    "\n        "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2592759487
                      ),
                    },
                    [
                      _c("EditPrices", {
                        attrs: { node: _vm.information },
                        on: { updatedPrice: _vm.updatedInformation },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }