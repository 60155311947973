var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { key: _vm.refreshKey },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c(
            "b-button",
            { staticClass: "no-border", on: { click: _vm.refreshList } },
            [_c("i", { staticClass: "fal fa-sync" })]
          ),
          _c(
            "AddNode",
            {
              key: _vm.addNodeKey,
              attrs: { license: _vm.license },
              on: { addedNode: _vm.refreshList },
            },
            [
              _c(
                "b-btn",
                { staticClass: "inlineBtn", attrs: { variant: "dark" } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("NODE.ADD_NODE")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("b-form-input", {
            staticClass: "searchText newline-mobile",
            attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _c(
            "b-form-select",
            {
              staticClass: "limit",
              attrs: { name: "list_nodes_limit" },
              on: { change: _vm.getNodes },
              model: {
                value: _vm.limit,
                callback: function ($$v) {
                  _vm.limit = $$v
                },
                expression: "limit",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: "10" } }, [
                _vm._v("10"),
              ]),
              _c("b-form-select-option", { attrs: { value: "25" } }, [
                _vm._v("25"),
              ]),
              _c("b-form-select-option", { attrs: { value: "45" } }, [
                _vm._v("45"),
              ]),
              _c("b-form-select-option", { attrs: { value: "100" } }, [
                _vm._v("100"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("b-table", {
            attrs: {
              small: "",
              bordered: "",
              items: _vm.items,
              hover: "",
              busy: _vm.loading,
              fields: _vm.fields,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u([
              {
                key: "cell(node_type)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("LICENSE." + data.item.node_type)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(data.item.description) +
                        "\n        "
                    ),
                    data.item.global_server_id == 0
                      ? _c("b-badge", { attrs: { variant: "info" } }, [
                          _vm._v(_vm._s(_vm.$t("STATUS_INVITED"))),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(hostname)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(data.item.hostname) + "\n      "
                    ),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [_c("b-spinner", { staticClass: "align-middle" })],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.totalItems,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getNodes },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "user-sidebar",
                "sidebar-class": "big_sidebar",
                right: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_sidebar,
                callback: function ($$v) {
                  _vm.show_sidebar = $$v
                },
                expression: "show_sidebar",
              },
            },
            [
              _c(
                "div",
                { staticClass: "px-3 py-2" },
                [
                  _vm.nodeId != ""
                    ? _c("NodePage", {
                        key: _vm.nodeId,
                        attrs: { node_id: _vm.nodeId, license: _vm.license },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }