<template>
  <section class="mt-2" v-if="license">
    
    <b-overlay :show="loading" rounded="sm">

      <b-form-group :label="$t('DESCRIPTION')">
        <b-form-input trim
          type="text"
          v-model="information.node.description"
          name="item.description"
        ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('LICENSE.MULTITENANCY')">
        <b-form-select
          v-model="information.node.multitenancy"
          :options="multitenancy"
        ></b-form-select>
      </b-form-group>

      <b-form-group :label="$t('TYPE')" v-if="license.type == 'PA'">
        <b-form-select
          v-model="information.node.node_type"
          :options="node_type"
        ></b-form-select>
      </b-form-group>

      
      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>

      <b-button v-if="isActivation" variant="danger" class="btn-fill float-right" @click="removeActivation">{{
        $t("REMOVE")
      }}</b-button>

    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["node","license"],
  data() {
    return {
      information: this.node,
      loading: false,
      show: false,
      multitenancy: [
        { value: 0, text: this.$t("NO") },
        { value: 1, text: this.$t("YES") },
      ],
      node_type: [
        { value: "EC", text: this.$t("LICENSE.EC") },
        { value: "RS", text: this.$t("LICENSE.RS") },
      ],
    };
  },
  methods: {
    editBtn: function() {
      let self = this;
      this.loading = true;
      let NodeAddDTO = {
        id: this.information.node.id,
        name: this.information.node.description,
        max_accounts: 0,
        organisation_id: this.information.node.organisation_id,
        multitenancy: parseInt(this.information.node.multitenancy),
        type: this.information.node.node_type,
      };
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/node/update", NodeAddDTO)
        .then(function() {
          self.loading = false;
          self.$emit("updatedInformation",self.information);
          self.$noty.info(self.$t("SAVED"));
        })
        .catch(function() {
          self.loading = false;
        });
    },
    removeActivation: function() {
      let self = this;
      this.loading = true;      
      this.$http
        .post(this.user.hostname + "/node/remove", {
          node_id: this.information.node.id
        })
        .then(function() {
          self.$emit("removedActivation",self.information);
          //self.$router.push({ name: "Administration.Nodes.List.Activations" });
          self.$noty.info(self.$t("REMOVED"));
        })
        .catch(function() {
          self.loading = false;
      });
    }
  },
  computed: {
    isActivation() {
      return this.information.node.global_server_id == 0;
    }
  },
  mounted: function() {},
};
</script>
<style></style>
