<template>
  <section :key="refreshKey">
    <b-form inline class="mt-2">
      <b-button @click="refreshList" class="no-border"><i class="fal fa-sync"></i></b-button>
      <AddNode @addedNode="refreshList" :key="addNodeKey" :license="license">
        <b-btn class="inlineBtn" variant="dark">
          {{ $t('NODE.ADD_NODE') }}
        </b-btn>
      </AddNode>
      <b-form-input trim
        class="searchText newline-mobile"
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        @keydown.enter.prevent
      ></b-form-input>
      <b-form-select  name="list_nodes_limit" v-model="limit" class="limit" @change="getNodes">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>
    </b-form>


    <div class="mt-2">
      <b-table
        small
        bordered
        :items="items"
        hover
        :busy="loading"
        :fields="fields"
        @row-clicked="clickedRow"
      >
        <template v-slot:cell(node_type)="data">
          {{ $t("LICENSE." + data.item.node_type) }}
        </template>

        <template v-slot:cell(id)="data">
          {{ data.item.description }}
          <b-badge variant="info" v-if="data.item.global_server_id == 0">{{$t('STATUS_INVITED')}}</b-badge>
        </template>


        <template v-slot:cell(hostname)="data">
          {{ data.item.hostname }}
        </template>

        

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        @input="getNodes"
        :total-rows="totalItems"
        :per-page="limit"
        align="center"
        pills
      >
      </b-pagination>

      

      <b-sidebar id="user-sidebar" sidebar-class="big_sidebar" right backdrop no-slide shadow v-model="show_sidebar">
        <div class="px-3 py-2">
          <NodePage 
            v-if="nodeId != ''"
            :node_id="nodeId" 
            :license="license"
            :key="nodeId">
          </NodePage>
        </div>
      </b-sidebar>

    </div>
  </section>
</template>
<script>
import AddNode from "@/components/Administration/AddNode";
import NodePage from '@/components/Node/Page';
export default {
  props: ["license"],
  components: {
    NodePage, AddNode
  },
  data() {
    return {
      refreshIndex: 0,
      searchText: '',
      limit: 10,
      loading: false,
      show_sidebar: false,
      node: null,
      nodeId: 0,
      nodeIndex: 0,
      currentPage: 1,
      items: [],
      totalItems: 0,
      state: 1,
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "description",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "hostname",
          label: this.$t("HOSTNAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "organisation_name",
          label: this.$t("ORGANISATION.TITLE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "node_type",
          label: this.$t("TYPE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
      ]
    };
  },
  methods: {
    refreshList(){
      this.getNodes();
      this.refreshIndex = this.refreshIndex + 1;
    },
    changeList() {
      this.currentPage = 1;
    },
    updatedNode(data, index) {
      this.items.$set(index, data);
    },
    removedNode(index) {
      this.items.splice(index, 1);
    },
    clickedRow(data) {
      this.nodeId = data.id;
      this.show_sidebar = true;

      /*this.$router.push({
        name: "Administration.Node",
        params: { node_id: data.id },
      });*/
    },
    getNodes: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/node/all/list", {
          page: this.currentPage,
          limit: parseInt(this.limit),
          state: 1,
          invited: 0,
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    showState: function(state) {
      if (state == 1) return this.$t("ACTIVE");
      if (state == 2) return this.$t("INACTIVE");
      if (state == 0) return this.$t("INVITED");
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
  computed: {
    refreshKey(){
      return 'refreshKey_' + this.refreshIndex;
    },
    addNodeKey(){
      return 'addNodeKey_' + this.refreshIndex;
    }
  },
  watch: {
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getNodes();
      }
      if (this.searchText.length >= 2) {
        this.getNodes();
      }
    }
  },
  mounted: function() {
    this.getNodes();
  },
};
</script>
<style></style>
