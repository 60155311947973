<template>
  <section v-if="information && license">

      <b-alert
        show
        variant="warning"
        v-if="information.node.global_server_id == 0"
        >{{ $t("ORGANISATION.NOT_ACTIVATED") }}</b-alert
      >
      <dl class="mt-0 pt-0 clearfix">
        <dt>{{ $t("DESCRIPTION") }}</dt>
        <dd>{{ information.node.description }}</dd>
        <dt v-if="information.node.global_server_id == 0">
          {{ $t("ACTIVATION_CODE") }}
        </dt>
        <dd v-if="information.node.global_server_id == 0">
          {{ information.node.activation_token }} <i class="fal fa-copy clickable" v-b-tooltip.hover @click="copyActicationToken" :title="$t('COPY_CLIPBOARD')"></i>
        </dd>
        <dt v-if="information.node.global_server_id != 0">
          {{ $t("HOSTNAME") }}
        </dt>
        <dd v-if="information.node.global_server_id != 0">
          {{ information.node.hostname }}
        </dd>
        <dt>{{ $t("ORGANISATION.TITLE") }}</dt>
        <dd>
          <router-link
            :to="{
              name: 'Administration.Organisation.Information',
              params: { organisation_id: information.organisation.id },
            }"
            >{{ information.organisation.name }}</router-link
          >
        </dd>
      </dl>

      <b-tabs>
        
        <b-tab lazy>
          <template v-slot:title>{{
              $t("INFORMATION")
            }}
          </template>  
          <Edit :license="license" @updatedInformation="updatedInformation" :node="information"></Edit>
        </b-tab>

        <b-tab lazy v-if="user.information.is_admin > 0">
          <template v-slot:title>
           {{
            $t("PRICES")
          }}
          </template>  
          <EditPrices @updatedPrice="updatedInformation" :node="information"></EditPrices>
        </b-tab>

      </b-tabs>
    
  </section>
</template>

<script>
import Edit from "@/components/Node/Edit";
import EditPrices from "@/components/Node/EditPrices";
export default {
  components: { Edit, EditPrices },
  props: ["node_id","license"],
  data() {
    return {
      keyIndex: 0,
      information: null
    };
  },
  methods: {
    copyActicationToken: function(){
      const el = document.createElement('textarea');
      el.value = this.information.node.activation_token;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$noty.info(this.$t("COPY_CLIPBOARD"));
    },
    getInformation: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/node/information/" + this.node_id)
        .then((response) => {
          self.keyCount = self.keyCount + 1;
          self.information = response.data;
        })
        .catch(() => {
        });
    },
    updatedInformation: function(updatedInformation) {
      this.information = updatedInformation;
      this.keyIndex++;
    },
  },
  computed: {
  },
  mounted() {
    this.getInformation();
  },
};
</script>
<style></style>
