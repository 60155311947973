var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("DESCRIPTION") } },
                [
                  _c("b-form-input", {
                    attrs: { trim: "", type: "text", name: "item.description" },
                    model: {
                      value: _vm.information.node.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.information.node, "description", $$v)
                      },
                      expression: "information.node.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("LICENSE.MULTITENANCY") } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.multitenancy },
                    model: {
                      value: _vm.information.node.multitenancy,
                      callback: function ($$v) {
                        _vm.$set(_vm.information.node, "multitenancy", $$v)
                      },
                      expression: "information.node.multitenancy",
                    },
                  }),
                ],
                1
              ),
              _vm.license.type == "PA"
                ? _c(
                    "b-form-group",
                    { attrs: { label: _vm.$t("TYPE") } },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.node_type },
                        model: {
                          value: _vm.information.node.node_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.information.node, "node_type", $$v)
                          },
                          expression: "information.node.node_type",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { variant: "primary" },
                  on: { click: _vm.editBtn },
                },
                [_vm._v(_vm._s(_vm.$t("SAVE")))]
              ),
              _vm.isActivation
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-fill float-right",
                      attrs: { variant: "danger" },
                      on: { click: _vm.removeActivation },
                    },
                    [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }