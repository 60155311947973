<template>
  <section class="mt-2">    

    
    <b-overlay :show="loading" rounded="sm">

      <Prices :prices="information.price"></Prices>

      
      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>

    </b-overlay>
  </section>
</template>
<script>
import Prices from "@/components/Input/Prices.vue";
export default {
  props: ["node"],
  components: { Prices },
  data() {
    return {
      information: this.node,
      show: false,
      loading: false
    };
  },
  methods: {
    editBtn: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname + "/node/edit-price", this.information.price
        )
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
          self.$emit("updatedPrice", result.data);
        })
        .catch(function() {
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
  },
};
</script>
<style></style>
